// eslint-disable-next-line import/prefer-default-export
const toCamel = s => s.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''))

// eslint-disable-next-line func-names
const isArray = function (a) {
  return Array.isArray(a)
}

// eslint-disable-next-line func-names
const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

// eslint-disable-next-line import/prefer-default-export,func-names
export const keysToCamel = function (o) {
  // eslint-disable-next-line no-undef
  if (isObject(o)) {
    const n = {}
    Object.keys(o)
      .forEach(k => {
        n[toCamel(k)] = keysToCamel(o[k])
      })
    return n
    // eslint-disable-next-line no-undef
  } if (isArray(o)) {
    return o.map(i => keysToCamel(i))
  }
  return o
}
