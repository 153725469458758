<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            ProfitPanda
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Авторизация
        </b-card-title>
        <b-card-text class="mb-2">
          Войдите в свою учетную запись
        </b-card-text>

        <!-- форма авторизации -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Введите ваш email"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- пароль -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Пароль</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Забыли пароль?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Пароль"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Пароль"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Запомнить меня
              </b-form-checkbox>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              <b-spinner
                v-show="isSignIn"
                small
              />
              <span v-show="!isSignIn">Войти</span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>У вас нет аккаунта? </span>
          <b-link :to="{name:'auth-register'}">
            <span>Регистрация</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BSpinner,
  VBTooltip,
  BCard,
} from 'bootstrap-vue'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { keysToCamel } from '@/libs/helpers'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: null,
      userEmail: null,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
      isSignIn: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const authParams = new URLSearchParams({
            username: this.userEmail,
            password: this.password,
            grant_type: 'password',
            scope: 'openid offline_access',
          })
          this.isSignIn = true
          useJwt.login(authParams, {
            headers:
                {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
          }).then(response => {
            const userData = keysToCamel(response.data)
            const userDataDemo = {
              userRole: 'admin',
              ability: [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ],
            }
            useJwt.setToken(userData.accessToken)
            useJwt.setRefreshToken(userData.refreshToken)

            localStorage.setItem('userData', JSON.stringify(userDataDemo))
            this.$ability.update(userDataDemo.ability)
            this.$router.replace(getHomeRouteForLoggedInUser(userDataDemo.userRole))
              .then(() => {
                this.isSignIn = false
              })
          })
            .catch(error => {
              const errorData = keysToCamel(error.response.data)
              this.isSignIn = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка авторизации',
                  icon: 'LogInIcon',
                  variant: 'danger',
                  text: errorData.errorDescription,
                },
              }, {
                position: 'top-center',
              })

              this.$refs.loginForm.setErrors(error.response.data.errorDescription)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
